.calender-wrap {
  display: flex;
  margin: 20px 0 40px;

  .calendar-month {
    width: 400px;
    border-radius: 4px;

    >.ant-fullcalendar-fullscreen {
      border: 1px solid #eee;
    }
  }

  .calendar-days {
    flex: 1;
    margin-left: 40px;
    >.ant-fullcalendar-fullscreen {
      border: 1px solid #eee;
    }

    .ant-fullcalendar-column-header-inner {
      text-align: center;
      line-height: 30px;
    }

    .date-cell {
      display: block;
      height: 80px;
      background: rgb(204, 204, 204);
      text-align: center;
      line-height: 80px;
      font-size: 28px;
      cursor: pointer;
      &.work-day {
        background: rgb(244, 157, 62);
      }

      &.disabled {
        background: rgba(204, 204, 204, 0.1);
        color: rgba(0, 0, 0, 0.1);
        cursor: default;
      }
    }
  }

  .calendar-btn {
    text-align: center;
    margin-top: 20px;
  }

  .calendar-workday {
    margin-top: 20px;
    border: 1px solid #ccc;
  }

  .workday-count {
    color: #999;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .calendar-header {
    display: flex;
    justify-content: center;
    padding: 10px 0;
  }
}

.calendar-tags {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;

  span {
    padding: 0 4px;
  }

  .workday-tag {
    background: rgb(244, 157, 62);
    margin-right: 20px;
  }

  .playday-tag {
    background: rgb(204, 204, 204);

  }
}

// 我的考勤
.self-wrap {
  display: flex;
  margin: 20px 0 40px;
  .filter-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    > label {
      width: 100px;
      text-align: right;
    }
  }
}

// 我的考勤
.self-con {
  .self-switch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 10px;
    .self-switch-text {
      margin-right: 10px;
    }
  }
  .alert-title {
    margin-right: 16px;
  }
  .alert-num {
    color: red;
    margin: 0 4px;
  }
}
.self-wrap-appeal {
  display: flex;
  justify-content: flex-start;
  .appealtext {
    display: flex;
    flex-direction: column;
    line-height: 50px;
  }
  .appealtextOne {
    margin-right: 60px;
  }
}

.self-wrap-table {
 margin-bottom: 26px; 
}

.self-wrap-intraday {
  font-weight: 700;
}

//请假外出
.search-askforleave-wrap {	
  margin: 10px 0;
  position: relative;
	.title{
		width: 100px;
  }
  .askforleave-filter-control{
    width: 36px;
    color: #1890ff;
    font-size: 12px;
    cursor: pointer;
    line-height: 22px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }
  
	form{
		> .form-box {
      height: 44px;
			width: 100%;
			overflow: hidden;
			.ant-form-item{
				float: left;
        width: 25%;
			}
			.ant-form-item-label{
				// text-align: left;
				padding-left: 5px;
			}
    }
    > .askforleave-form-auto{
      height: auto;
    }
		.btns{
			text-align: right;
			padding-right: 0px;
			button{
				margin-left: 10px;
			}
		}
	}
}
.shouldme-switch-btn {
    height: 30px !important;
    line-height: 28px !important;
    padding: 0 10px !important;
    margin-right: 10px !important;
    .ant-switch-inner{
      font-size: 14px;
    }
}
.shouldme-switch-btn::after {
  width: 24px !important;
  height: 24px !important;
}
.search-askforleave-wrap form > .form-box .ant-form-item{
    min-width: 220px;
  //全员考勤
  .card-container {
    .ant-tabs-card {
      .ant-tabs-content {
        height: 120px;
        margin-top: -16px;
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-content {
        .ant-tabs-tabpane {
          background: #fff;
          padding: 16px;
        }
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar {
        border-color: #fff;
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar {
        .ant-tabs-tab {
          border-color: transparent;
          background: transparent;
        }
      }
    }
  }

  .card-container {
    .ant-tabs-card {
      .ant-tabs-bar {
        .ant-tabs-tab-active {
          border-color: #fff;
          background: #fff;
        }
      }
    }
  }
}
.ant-form-item-control {
  .ant-form-item-children {
    .longStyle {
      width: 150px;
    }
  }
}

.ant-form-horizontal {
  .whole-leave-time {
    width: 220px;
    position: absolute;
    right: -80px;
    top: 0;
  }
  .whole-leave-times {
    width: 220px;
    float: right;
    right: -80px;
  }
} 

.whole-time {
  width: 360px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 120px;
  margin-bottom: 30px;
  .whole-time-name {
    width: 50%;
  }
}

// 请假外出申请详情页
.add-newask-detail {
  .orange-btn{
    background: #F29D1C;
    border-color: #F29D1C;
    color: #fff;
  }
	.pro-detail-title {
		margin-bottom: 10px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}
	.pro-detail-smailtitle{
		> .ant-descriptions-title {
			font-size: 14px;
			height: 34px;
		}
	}
	.step-wrap {
		padding: 20px 25% 20px 20px;
		box-sizing: border-box;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		margin-bottom: 10px;
	}
	.info-box{
		padding-bottom: 20px;
		margin-bottom: 10px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		.content{
			line-height: 1.5em;
			height: 1.5em;
		}
		.info{
			color: #3490FD;
		}
	}
	.btns{
		text-align: center;
		margin: 30px 0;
		button{
			margin-left: 10px;
		}
  }
  .step-wrap{
    border-bottom: none;
    padding: 20px 0 20px 0;
  }
	.record-list{
    margin: 20px 0;    
  }
  .record-list-title{
    font-weight: bold;
    padding-bottom: 10px;
    font-size: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .blank-line{
    height: 10px;
    background: #f0f2f5;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
  .table-wrap{
    margin: 35px 0;
    .table-wrap-title{
      font-weight: bold;
      padding-bottom: 20px;
    }
  }
  .ask-info{
    margin: 15px 0;
  }
  .ant-descriptions-item-content{
    color: #989898;
  }
}

//考勤统计
.statistics-form {
  .statistics-label.ant-form-item {
    width: 500px;
    .ant-form-item-label {
      width: 100px;
      label {
        margin-left: -50px;
      }
    }
    .ant-form-item-control-wrapper {
      .staffalteration-filter {
        position: relative;
        .staffalteration-btn-a {
          position: absolute;
          right: -2px;
          top: -72px;
        }
      }
    }
  }
}
.statistics-group {
  position: absolute;
  right: 100px;
  top: -75px;
}  
.statistics-btn-up {
  top: -140px !important;
}

.activeRed {
  color: red;
}



